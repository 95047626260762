.divButton {
  width: 200px;
  height: 200px;
}

/*
--- ARCADE BUTTON ---
Link: https://codepen.io/mkvug/pen/meJyr
<a id="button">Click Me</a>
*/
.button {
  position: relative;
  display: block;
  text-align: center;
  font-size: 1.75em;
  color: #ffe5e5;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  background: #f00;
  width: 140px;
  height: 140px;
  top: 50%;
  left: 50%;
  margin: -74px 0 0 -75px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 -5px 20px rgba(0, 0, 0, 0.5),
    inset 0 15px 20px rgba(255, 255, 255, 0.5), 0 5px 10px rgba(0, 0, 0, 0.75),
    0 10px 10px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 -5px 20px rgba(0, 0, 0, 0.5),
    inset 0 15px 20px rgba(255, 255, 255, 0.5), 0 5px 10px rgba(0, 0, 0, 0.75),
    0 10px 10px rgba(0, 0, 0, 0.5);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: -3px 5px 0 0;
  cursor: pointer !important;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  transition: all 0.1s ease;

  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 2.5vw; */
  text-decoration: none;
}
.button:before {
  content: " ";
  display: block;
  position: absolute;
  z-index: -5;
  width: 180px;
  height: 180px;
  top: 50%;
  left: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #f00;
  top: -15px;
  left: -20px;
  -webkit-box-shadow: inset 0 7px 15px rgba(255, 255, 255, 0.5),
    inset 0 -7px 15px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 7px 15px rgba(255, 255, 255, 0.5),
    inset 0 -7px 15px rgba(0, 0, 0, 0.5);
}

.button:active {
  -webkit-box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
  color: #ffeded;
}

.button:hover {
  background: rgb(221, 0, 0);
}

.buttonIndex:active {
  -webkit-box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
  color: grey;
}

.buttonIndex:hover {
  background-color: rgb(200 200 200);
}

.buttonIndex {
  position: relative;
  display: block;
  text-align: center;
  font-size: 20px;
  color: #180101;
  text-shadow: 1px 1px 3px rgba(200, 200, 200, 0.5);
  background: rgb(255, 255, 255);
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  /* margin: -74px 0 0 -75px; */
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 -5px 20px rgba(0, 0, 0, 0.5),
    inset 0 15px 20px rgba(255, 255, 255, 0.5), 0 5px 10px rgba(0, 0, 0, 0.75),
    0 10px 10px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 -5px 20px rgba(0, 0, 0, 0.5),
    inset 0 15px 20px rgba(255, 255, 255, 0.5), 0 5px 10px rgba(0, 0, 0, 0.75),
    0 10px 10px rgba(0, 0, 0, 0.5);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: -3px 5px 0 0;
  cursor: pointer;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  transition: all 0.1s ease;

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.buttonIndex:before {
  content: " ";
  display: block;
  position: absolute;
  z-index: -5;
  width: 120px;
  height: 120px;
  top: 50%;
  left: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  top: -15px;
  left: -20px;
  -webkit-box-shadow: inset 0 7px 15px rgba(255, 255, 255, 0.5),
    inset 0 -7px 15px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 7px 15px rgba(255, 255, 255, 0.5),
    inset 0 -7px 15px rgba(0, 0, 0, 0.5);
}

.tag {
  position: absolute;
  color: #363232;
  text-shadow: 1px 1px 1px #b0b0b0;
  margin-top: 20px;
  margin-left: 16px;
  z-index: 10;
}

.tagNew {
  color: #ffeb3b;
  text-shadow: 1px 2px 1px #030303;
  font-style: italic;
}

@media (max-width: 480px) {
  .divButton {
    width: 100px;
    height: 125px;
  }

  a.button {
    font-size: 3vw;
    width: 70px;
    height: 70px;
    /* padding: 0px 5px 0 0; */
    margin: -50px 0 0 -25px;
  }

  a.button:before {
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    top: -13px;
    left: -15px;
    -webkit-box-shadow: inset 0 7px 15px rgba(255, 255, 255, 0.5),
      inset 0 -7px 15px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 7px 15px rgba(255, 255, 255, 0.5),
      inset 0 -7px 15px rgba(0, 0, 0, 0.5);
  }

  .headerIndex {
    width: 100px;
  }

  a.buttonIndex {
    font-size: 3vw;
    width: 50px;
    height: 50px;
  }

  a.buttonIndex:before {
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    top: -13px;
    left: -15px;
    -webkit-box-shadow: inset 0 7px 15px rgba(255, 255, 255, 0.5),
      inset 0 -7px 15px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 7px 15px rgba(255, 255, 255, 0.5),
      inset 0 -7px 15px rgba(0, 0, 0, 0.5);
  }

  .tag {
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
    font-size: x-small;
  }
}
