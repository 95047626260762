.share {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shareSocial {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.shareGooglePlayDescription {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.shareGooglePlayDescription:hover {
  color: yellow;
}

.shareInstallation {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.shareGooglePlay {
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  gap: 10px;

  background-color: #3ddc848c;
  padding-top: 3px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 3px;
  border-radius: 25px;
}

.shareSugerencias {
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  margin-bottom: 10px;

  background-color: #bfdb418c;
  padding-top: 3px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 3px;
  border-radius: 25px;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.installWPA {
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  gap: 10px;
  color: white;

  background-color: #a2aaad8c;
  padding-top: 3px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 3px;
  border-radius: 25px;
}

.titleGooglePlay {
  text-decoration: none;
  color: white;
}

.googlePlayLogo {
  width: 100px;
}

.androidLogo {
  width: 24px;
}

@media (max-width: 601px) {
  .shareInstallation {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .share {
    margin-top: 20px;
    margin-bottom: 17px;
    width: 100%;
  }
}
