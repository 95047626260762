.container-brush {
  display: flex;
  justify-content: center;
}

/* create wrapper */
.brush-wrap {
  position: relative;
  display: inline-block;
  padding: 2rem;
}

/* applying example animation (indefinite variant) */
.brush-wrap.brush-wrap--indefinite:before {
  clip-path: url(#clip-indefinite);
}

/* clipping/animating object (pseudo element) */
.brush-wrap:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: black;
  z-index: -1;
  clip-path: url(#clip); /* applying clip animation */
}

.brush-wrap p {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: 0;
  color: white;
  font-style: italic;
  filter: drop-shadow(0px 0px 2px black);
}
